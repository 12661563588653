import React, { useEffect } from "react";
import {
  AccessWrapper,
  CafeInfoWrapper,
  CafeWrapper,
  ConceptWrapper,
  Layout,
  NewsWrapper,
  PickupWrapper,
  TopWrapper,
  VibeWrapper,
} from "../components";

import Seo from "../components/Seo";

const IndexPage = () => {
  useEffect(() => {
    if (window.location.pathname === "/") {
      const header = document.querySelector("header");
      const nav = document.querySelector(".nav");
      header.classList.remove("lg:bg-white");
      nav.classList.add("lg:text-white");
      window.addEventListener("scroll", function () {
        let y = window.pageYOffset;
        if (y > 400) {
          header.classList.add("lg:bg-white");
          nav.classList.remove("lg:text-white");
        } else if (y < 400) {
          header.classList.remove("lg:bg-white");
          nav.classList.add("lg:text-white");
        }
      });
    }
  });

  return (
    <Layout>
      <Seo title="HOME" />
      <TopWrapper />
      <PickupWrapper />
      <ConceptWrapper />
      <CafeWrapper />
      <VibeWrapper />
      {/* <MenuWrapper /> */}
      <CafeInfoWrapper />
      <NewsWrapper />
      <AccessWrapper />
    </Layout>
  );
};

export default IndexPage;
